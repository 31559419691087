import request from "@/utils/request";

export const signInApi = (data: any = {}): Promise<any> =>
  request({
    url: `auth/sign-in`,
    method: "post",
    data: data,
  });

export const authMeApi = (): Promise<any> =>
  request({
    url: `auth/me`,
    method: "get",
  });
