
import { defineComponent } from "vue";
import { PageService } from "@/services/PageService";

export default defineComponent({
  name: "Header",
  props: [],
  watch: {},
  data() {
    return {
      $store: this.$store as any,
      pageService: new PageService(),
    };
  },
  components: {},
  created: async function () {},
  mounted: async function (): Promise<void> {},
  methods: {
    logout: function () {
      this.$store.deleteAuthData();
      this.$router.push({
        name: "AuthLogin",
      });
    },
  },
});
