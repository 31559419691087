import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store/index";

const routes: Array<RouteRecordRaw> = [
  { path: "/", name: "Home", redirect: { name: "NewOrderIndex" } },
  {
    path: "/auth/sign-in",
    name: "SignIn",
    component: () => import("@/views/auth/SignIn.vue"),
  },
  {
    path: "/sales/month/:yearMonth",
    name: "SaleMonth",
    component: () => import("@/views/sales/Month.vue"),
  },
  {
    path: "/sales/week/:date",
    name: "SaleWeek",
    component: () => import("@/views/sales/Week.vue"),
  },
  {
    path: "/sales/date/:date",
    name: "SaleDate",
    component: () => import("@/views/sales/Date.vue"),
  },
  {
    path: "/new-order",
    name: "NewOrderIndex",
    component: () => import("@/views/new-order/Index.vue"),
  },
  {
    path: "/product",
    name: "ProductIndex",
    component: () => import("@/views/product/Index.vue"),
  },
  {
    path: "/comment",
    name: "CommentIndex",
    component: () => import("@/views/comment/Index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next): Promise<void> => {
  await store.dispatch("setAuthData");
  if (to.name !== "SignIn" && !store.state.isLogin) {
    next("/auth/sign-in");
    return;
  }
  next();
});

export default router;
