import axios from "axios";
import vm from '@/main';


const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL + 'api/',
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken !== undefined) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    let resultData: any = {
      data: response.data.data,
      meta: response.data.meta,
      status: response.status,
      success: true,
      message: ''
    };
    if (response.data.message) {
      resultData.message = response.data.message ?? "";
    }
    console.log(resultData);
    return resultData;
  },
  (error) => {
    let resultData: any = {
      data: null,
      status: error.response.status,
      success: false,
      message: '',
    };
    if (resultData.status >= 500) {
      vm.$router.push({
        name: 'Error500'
      })
    }
    if (error.response.status === 422 && error.response?.data?.errors) {
      resultData.data = error.response.data?.errors;
    }
    if (error.response.data.message) {
      resultData.message = error.response.data.message ?? "";
    }
    console.log(resultData);
    return resultData;
  }
);

export default service;
