import { createStore } from "vuex";
import { authMeApi } from "@/api/auth";


export default createStore({
    state: {
        myData: null as any,
        isLogin: false,
    },
    mutations: {
        setAuthData(state, payload) {
            state.myData = payload.myData;
            state.isLogin = payload.isLogin;
        },
        deleteAuthData(state) {
            state.myData = null;
            state.isLogin = false;
        },
    },
    actions: {
        setAuthData: async ({ commit, state }): Promise<void> => {
            if (state.isLogin) {
                return;
            }

            try {
                let meResult = await authMeApi();
                if (!meResult.success) {
                    commit("setAuthData", {
                        myData: null,
                        isLogin: false,
                    });
                    return;
                }

                commit("setAuthData", {
                    myData: meResult.data,
                    isLogin: true,
                });

            } catch (error) {
                commit("setAuthData", {
                    myData: null,
                    isLogin: false,
                });
            }
        },
        deleteAuthData: ({ commit, state }): void => {
            commit("setAuthData", {
                myData: null,
                isLogin: false,
            });
        },
    },
});
