
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  props: [],
  watch: {},
  data() {
    return {};
  },
  components: {},
  created: async function () {},
  mounted: async function (): Promise<void> {},
  methods: {},
});
