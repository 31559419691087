
import { defineComponent } from "vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "App",
  data() {
    return {};
  },
  components: {
    Footer,
    Header,
  },
  created: function (): void {},
  mounted: async function (): Promise<void> {},
  methods: {},
});
